<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      ok-title="Save"
      header-close-content=""
      title="Update Listener Log 3"
      centered
      scrollable
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-container fluid>
        <b-row>
          <!-- <b-col v-for="([key], index) in Object.entries(editedItem)" :key="index" cols="12">
            <b-form-group :id="key">
              <label for="nameInput">{{ key }} <span class="text-danger">*</span></label>
              <b-form-input
                :id="key + '_input'"
                :name="key"
                v-model="editedItem[key]"
                type="text"
                :class="{ input: true, 'is-danger': errors.has(`${key}`) }"
                v-validate="{ required: true, min: 1, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col> -->

          <b-col
            v-for="({ key, cols, required, disabled, type, validate }, index) in editedItemInput.filter(
              ({ key }) => (key === 'id' && editedItem[key]) || key !== 'id'
            )"
            :key="index"
            :cols="cols"
          >
            <b-form-group :id="key">
              <label for="nameInput">{{ key }} <span v-if="required" class="text-danger">*</span></label>
              <!-- <v-switch v-if="type === 'switch'" v-model="editedItem[key]" color="info" hide-details></v-switch> -->
              <b-form-checkbox
                v-if="type === 'switch'"
                v-model="editedItem[key]"
                hide-details
                :id="key + '_input'"
                :name="key"
                :disabled="disabled"
                switch
                size="lg"
              >
              </b-form-checkbox>

              <b-form-select
                v-else-if="type === 'select' && key === 'payment_id'"
                v-model="editedItem.payment_id"
                :options="paymentListData"
              ></b-form-select>
              <b-form-input
                v-else
                :id="key + '_input'"
                :name="key"
                :disabled="disabled"
                v-model="editedItem[key]"
                :type="type"
                :aria-describedby="`input-${key}`"
                :data-vv-as="key"
                :class="{ input: true, 'is-danger': errors.has(`${key}`) }"
                :v-validate="validate"
              ></b-form-input>
              <span v-show="errors.has(`${key}`)" class="is-danger-text position-relative">{{
                errors.first(`${key}`)
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Listener Log 3</h4>
      </b-col>
      <b-col cols="12" class="btnAdd text-right mb-1">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
      <!-- <b-col cols="2">
        <b-form-group label="Payment" label-for="payment_filter">
          <b-form-select
            id="payment_filter"
            v-model="filterPaymentValue"
            :options="filterPaymentList"
            @change="handleChangeFilter"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Status" label-for="status_filter">
          <b-form-select
            id="status_filter"
            v-model="filterStatusValue"
            :options="filterStatusList"
            @change="handleChangeFilterStatus"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col> -->
      <!-- Filter UserName -->
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="listLogHistoryData.data.map((item) => ({ ...item, action: '' }))"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
      :busy="isLoading"
    >
      <!-- HEAD -->

      <!-- CELL -->

      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="">Inactive</b-badge>
      </template>

      <!-- <template #cell(format)="item"> </template> -->

      <template #cell(actions)="item">
        <v-icon class="text-success" @click="handleEditedItem(item.item)">mdi-pencil</v-icon>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        {{ footerTableInfo }}
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="getData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="listLogHistoryData.total"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="getData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Swal from 'sweetalert2';
import ApiService from '@/common/api.service';
// import TabinTable from './TabinTable';
export default {
  // components: { TabinTable },
  data() {
    return {
      listLogHistoryData: {
        data: [],
        total: 0
      },

      paymentListData: [],

      filterPaymentList: [
        { value: 1, text: 'Payment 1' },
        { value: 2, text: 'Payment 2' }
      ],
      filterStatusList: [
        { value: 1, text: 'Status 1' },
        { value: 2, text: 'Status 2' }
      ],

      filterPaymentValue: null,
      filterStatusValue: null,

      perPage: 10,
      currentPage: 1,

      isLoading: true,

      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Bundle name', key: 'bundle_name' },
        { text: 'Regex format', key: 'regex_format' },
        { text: 'Manual amount', key: 'manual_amount' },
        { text: 'Payment id', key: 'payment_id' },
        { text: 'Notice', key: 'notice' },
        { text: 'Blacklist', key: 'blacklist_keywords' },
        { text: 'Error format', key: 'error_format' },
        { text: 'Status', key: 'status' },
        { text: 'Action', key: 'actions' }
      ],
      editedItem: {
        id: '',
        bundle_name: '',
        regex_format: '',
        manual_amount: '',
        payment_id: '',
        blacklist_keywords: '',
        error_format: '',
        notice: '',
        status: false
      },

      editedItemInput: [
        {
          key: 'id',
          disabled: true,
          required: true,
          type: 'text',
          validate: 'required|min:1|max:256',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'bundle_name',
          disabled: false,
          required: true,
          type: 'text',
          validate: 'required|min:1|max:256',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'regex_format',
          disabled: false,
          required: true,
          type: 'text',
          validate: 'required|min:1|max:256',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'manual_amount',
          disabled: false,
          required: true,
          type: 'number',
          validate: 'required|min:1|max:256',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'payment_id',
          disabled: false,
          required: true,
          type: 'select',
          validate: 'required|min:1|max:256',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'notice',
          disabled: false,
          required: false,
          type: 'text',
          validate: 'required|min:1|max:256',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'blacklist_keywords',
          disabled: false,
          required: false,
          type: 'text',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'error_format',
          disabled: false,
          required: false,
          type: 'text',
          cols: 6,
          placeholder: ''
        },
        {
          key: 'status',
          disabled: false,
          required: false,
          type: 'switch',
          validate: 'required|min:1|max:256',
          cols: 6,
          placeholder: ''
        }
      ],

      dialog: false
    };
  },
  created() {
    this.getData();
  },
  computed: {
    paramsListNotifyHistory() {
      return { params: { limit: this.perPage, offset: this.currentPage } };
    },
    footerTableInfo() {
      const { currentPage, perPage, listLogHistoryData } = this;
      const rows = listLogHistoryData.total;
      return `Showing ${(currentPage - 1) * perPage + 1}-${currentPage * perPage > rows ? rows : currentPage * perPage}
        of ${rows}.`;
    }
  },
  methods: {
    handleChangeFilter() {},
    handleChangeFilterStatus() {},
    resetModal() {
      // this.editedItem = null;
    },
    handleEditedItem: function (item) {
      this.dialog = true;
      this.editedItem = {
        ...item,
        status: !!item.status
      };
    },
    async getData() {
      this.isLoading = true;
      try {
        const [dataRes, paymentRes] = await Promise.all([
          ApiService.query('/payment/notify-listeners', this.paramsListNotifyHistory).then((res) => res.data),
          ApiService.query('/payments', { params: { limit: 100, offset: 1 } }).then((res) => res.data)
        ]);
        const { status, data } = dataRes;
        const { status: statusPayment, data: dataPayment } = paymentRes;

        if (status) {
          this.listLogHistoryData = data;
        } else {
          throw new Error("Can't load list history data !");
        }

        if (statusPayment) {
          this.paymentListData = dataPayment.paymentList.map((item) => ({ value: item.id, text: item.name }));
        } else {
          throw new Error("Can't load payment data !");
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: e?.message || "Can't load data! Try again!",
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateData() {
      this.editedItem.payment_id = parseInt(this.editedItem.payment_id);
      this.editedItem.manual_amount = parseInt(this.editedItem.manual_amount);
      this.editedItem.status = this.editedItem.status ? '1' : '0';
      try {
        const datacode = await ApiService.patch(
          '/payment/notify-listeners/' + this.editedItem.id,
          this.removeFalsyKey(this.editedItem)
        );
        if (datacode.status === 204) {
          this.getData();
          Swal.fire({
            icon: 'success',
            title: 'Update success!',
            showConfirmButton: false,
            timer: 3000
          });
        } else {
          throw new Error('Something wrong !');
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: "Can't update data! Try again!",
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        // this.isLoading = false;
        this.dialog = false;
      }
    },
    async createData() {
      this.editedItem.payment_id = parseInt(this.editedItem.payment_id);
      this.editedItem.manual_amount = parseInt(this.editedItem.manual_amount);
      try {
        const dataRes = await ApiService.post('/payment/notify-listeners', this.removeFalsyKey(this.editedItem)).then(
          (res) => res.data
        );

        const { status } = dataRes;
        if (status) {
          this.getData();
          this.dialog = false;
          Swal.fire({
            icon: 'success',
            title: 'Create success!',
            showConfirmButton: false,
            timer: 3000
          });
        } else {
          throw new Error('Something wrong !');
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: "Can't create data! Try again!",
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        // this.isLoading = false;
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const { id } = this.editedItem;
      if (id) {
        this.updateData();
      } else {
        this.$validator.validateAll().then((valid) => {
          if (valid) {
            this.createData();
          }
        });
      }
    },
    addItem: function () {
      this.dialog = true;
      this.editedItem = {
        bundle_name: '',
        regex_format: '',
        manual_amount: '',
        payment_id: '',
        blacklist_keywords: '',
        error_format: '',
        notice: '',
        status: ''
      };
    }
  }
};
</script>

<style></style>
